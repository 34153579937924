import unhead_aEsBflEYLv from "/codebuild/output/src1286400467/src/mosaic-land/node_modules/.pnpm/nuxt@3.11.1_@parcel+watcher@2.4.1_@types+node@22.5.4_@unocss+reset@0.58.9_axios@1.7.5_e_f9662f9f4a2dda7244c5bde90b497fb7/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_Zu2zMKf133 from "/codebuild/output/src1286400467/src/mosaic-land/node_modules/.pnpm/nuxt@3.11.1_@parcel+watcher@2.4.1_@types+node@22.5.4_@unocss+reset@0.58.9_axios@1.7.5_e_f9662f9f4a2dda7244c5bde90b497fb7/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_VbaUWfHSYP from "/codebuild/output/src1286400467/src/mosaic-land/node_modules/.pnpm/nuxt@3.11.1_@parcel+watcher@2.4.1_@types+node@22.5.4_@unocss+reset@0.58.9_axios@1.7.5_e_f9662f9f4a2dda7244c5bde90b497fb7/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_GFbLwlJD2V from "/codebuild/output/src1286400467/src/mosaic-land/node_modules/.pnpm/nuxt@3.11.1_@parcel+watcher@2.4.1_@types+node@22.5.4_@unocss+reset@0.58.9_axios@1.7.5_e_f9662f9f4a2dda7244c5bde90b497fb7/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_DhxnRlCus8 from "/codebuild/output/src1286400467/src/mosaic-land/node_modules/.pnpm/@pinia+nuxt@0.5.1_rollup@4.13.2_typescript@5.4.3_vue@3.4.21_typescript@5.4.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/codebuild/output/src1286400467/src/mosaic-land/.nuxt/components.plugin.mjs";
import prefetch_client_BUYJ4lghIf from "/codebuild/output/src1286400467/src/mosaic-land/node_modules/.pnpm/nuxt@3.11.1_@parcel+watcher@2.4.1_@types+node@22.5.4_@unocss+reset@0.58.9_axios@1.7.5_e_f9662f9f4a2dda7244c5bde90b497fb7/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import floating_vue_EIcJ7xiw0h from "/codebuild/output/src1286400467/src/mosaic-land/.nuxt/floating-vue.mjs";
import leaflet_runtime_7KZh71FHQb from "/codebuild/output/src1286400467/src/mosaic-land/node_modules/.pnpm/nuxt3-leaflet@1.0.12_rollup@4.13.2_typescript@5.4.3/node_modules/nuxt3-leaflet/dist/runtime/leaflet-runtime.mjs";
import chunk_reload_client_yLTnijvbUa from "/codebuild/output/src1286400467/src/mosaic-land/node_modules/.pnpm/nuxt@3.11.1_@parcel+watcher@2.4.1_@types+node@22.5.4_@unocss+reset@0.58.9_axios@1.7.5_e_f9662f9f4a2dda7244c5bde90b497fb7/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import auth_vT9JWWT9pN from "/codebuild/output/src1286400467/src/mosaic-land/plugins/auth.ts";
import bootstrap_client_Uyd73t1ylZ from "/codebuild/output/src1286400467/src/mosaic-land/plugins/bootstrap.client.ts";
import click_outside_client_QHauXawCCo from "/codebuild/output/src1286400467/src/mosaic-land/plugins/click-outside.client.ts";
import leaflet_client_7dEOJA4hbk from "/codebuild/output/src1286400467/src/mosaic-land/plugins/leaflet.client.ts";
import moment_NWWiRYbNqj from "/codebuild/output/src1286400467/src/mosaic-land/plugins/moment.ts";
import sentry_3AyO8nEfhE from "/codebuild/output/src1286400467/src/mosaic-land/plugins/sentry.ts";
export default [
  unhead_aEsBflEYLv,
  router_Zu2zMKf133,
  payload_client_VbaUWfHSYP,
  check_outdated_build_client_GFbLwlJD2V,
  plugin_vue3_DhxnRlCus8,
  components_plugin_KR1HBZs4kY,
  prefetch_client_BUYJ4lghIf,
  floating_vue_EIcJ7xiw0h,
  leaflet_runtime_7KZh71FHQb,
  chunk_reload_client_yLTnijvbUa,
  auth_vT9JWWT9pN,
  bootstrap_client_Uyd73t1ylZ,
  click_outside_client_QHauXawCCo,
  leaflet_client_7dEOJA4hbk,
  moment_NWWiRYbNqj,
  sentry_3AyO8nEfhE
]