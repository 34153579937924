import { default as _91id_93BGb5vayPyEMeta } from "/codebuild/output/src1286400467/src/mosaic-land/pages/import/[id].vue?macro=true";
import { default as indexnd6tgF9XzpMeta } from "/codebuild/output/src1286400467/src/mosaic-land/pages/import/index.vue?macro=true";
import { default as indexMnjQPQfhs7Meta } from "/codebuild/output/src1286400467/src/mosaic-land/pages/index.vue?macro=true";
import { default as loginR7yd1TL23QMeta } from "/codebuild/output/src1286400467/src/mosaic-land/pages/login.vue?macro=true";
import { default as newl1YeJa9i7LMeta } from "/codebuild/output/src1286400467/src/mosaic-land/pages/trade/new.vue?macro=true";
export default [
  {
    name: _91id_93BGb5vayPyEMeta?.name ?? "import-id",
    path: _91id_93BGb5vayPyEMeta?.path ?? "/import/:id()",
    meta: _91id_93BGb5vayPyEMeta || {},
    alias: _91id_93BGb5vayPyEMeta?.alias || [],
    redirect: _91id_93BGb5vayPyEMeta?.redirect,
    component: () => import("/codebuild/output/src1286400467/src/mosaic-land/pages/import/[id].vue").then(m => m.default || m)
  },
  {
    name: indexnd6tgF9XzpMeta?.name ?? "import",
    path: indexnd6tgF9XzpMeta?.path ?? "/import",
    meta: indexnd6tgF9XzpMeta || {},
    alias: indexnd6tgF9XzpMeta?.alias || [],
    redirect: indexnd6tgF9XzpMeta?.redirect,
    component: () => import("/codebuild/output/src1286400467/src/mosaic-land/pages/import/index.vue").then(m => m.default || m)
  },
  {
    name: indexMnjQPQfhs7Meta?.name ?? "index",
    path: indexMnjQPQfhs7Meta?.path ?? "/",
    meta: indexMnjQPQfhs7Meta || {},
    alias: indexMnjQPQfhs7Meta?.alias || [],
    redirect: indexMnjQPQfhs7Meta?.redirect,
    component: () => import("/codebuild/output/src1286400467/src/mosaic-land/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginR7yd1TL23QMeta?.name ?? "login",
    path: loginR7yd1TL23QMeta?.path ?? "/login",
    meta: loginR7yd1TL23QMeta || {},
    alias: loginR7yd1TL23QMeta?.alias || [],
    redirect: loginR7yd1TL23QMeta?.redirect,
    component: () => import("/codebuild/output/src1286400467/src/mosaic-land/pages/login.vue").then(m => m.default || m)
  },
  {
    name: newl1YeJa9i7LMeta?.name ?? "trade-new",
    path: newl1YeJa9i7LMeta?.path ?? "/trade/new",
    meta: newl1YeJa9i7LMeta || {},
    alias: newl1YeJa9i7LMeta?.alias || [],
    redirect: newl1YeJa9i7LMeta?.redirect,
    component: () => import("/codebuild/output/src1286400467/src/mosaic-land/pages/trade/new.vue").then(m => m.default || m)
  }
]